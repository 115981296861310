<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">申请补卡管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form
      ref="queryForm"
      :model="queryModel"
      inline
      class="demo-form-inline"
    >
      <el-form-item label="申请人姓名" prop="personName">
        <el-input
          type="text"
          size="mini"
          v-model="queryModel.personName"
        ></el-input>
      </el-form-item>
      <el-form-item label="单位" prop="parentId">
        <el-select-tree
          size="mini"
          :props="props"
          :options="companyResult"
          v-model="queryModel.parentId"
          height="200"
        ></el-select-tree
        >&nbsp;
        <el-checkbox v-model="queryModel.subordinate"
          >是否包含下级单位</el-checkbox
        >
      </el-form-item>
      <el-form-item label="申请类型" prop="type">
        <el-select
          v-model="queryModel.type"
          size="mini"
          filterable
          placeholder="请选择"
          style="width: 100px"
        >
          <el-option
            v-for="type in typeData"
            :key="type.value"
            :label="type.name"
            :value="type.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="审批状态" prop="status">
        <el-input
          type="text"
          size="mini"
          v-model="queryModel.status"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
          >查询</el-button
        >&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
          >重置</el-button
        >&nbsp;
      </el-form-item>
      <el-form-item label="补卡日" prop="fillDayRange">
        <el-date-picker
          v-model="queryModel.fillDayRange"
          type="daterange"
          size="mini"
          range-separator="至"
          placeholder="选择时间范围"
          value-format="yyyy-MM-dd"
          :clearable="false"
          style="width: 300px"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group"></el-row>
    <el-table
      ref="formTable"
      :data="tableData"
      :height="tableHeight"
      v-loading="loading"
      stripe
    >
      <el-table-column
        type="index"
        label="序号"
        :index="indexMethod"
        width="50"
      ></el-table-column>
      <el-table-column
        prop="personId"
        label="申请人编号"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="personName"
        label="申请人"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="companyName"
        label="申请单位"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="typeN"
        label="申请类型"
        width="80"
      ></el-table-column>
      <el-table-column
        prop="fillDay"
        label="补卡日"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="fillAttendanceTime"
        label="补卡时间"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="content"
        label="内容"
        width="180"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="approvalPersonName"
        label="审批人"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="statusN"
        label="审批状态"
        width="80"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        width="160"
      ></el-table-column>
      <el-table-column label fixed="right">
        <template slot-scope="{ row }">
          <el-button size="mini" type="warning" @click="handleOpenFiles(row)"
            >查看附件</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <fillAttendanceFiles-list
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></fillAttendanceFiles-list>
  </div>
</template>
<script>
import Constant from "@/constant";
import fillAttendanceApi from "@/api/business/fillAttendance";
import dataDictionaryApi from "@/api/sys/dataDictionary";
import companyInfoApi from "@/api/base/companyInfo";
import FillAttendanceFilesList from "./fillAttendanceFiles-list";
import pageUtil from "@/utils/page";
import SelectTree from "@/components/SelectTree";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: "BusinessWorkAttendanceList",
  data() {
    var self = this;

    return {
      queryModel: {
        personName: "",
        parentId: "",
        subordinate: false,
        type: "",
        status: "",
        fillDayRange: ["", ""],
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      typeData: [],
      companyResult: [],
      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children",
      },
      tableHeight: 400,
    };
  },
  created() {
    var formData = new FormData();
    formData.append("catalogName", "补卡类型");
    dataDictionaryApi.findByCatalogName(formData).then((response) => {
      var jsonData = response.data;
      this.typeData = jsonData.data;
    });

    companyInfoApi.treeList().then((response) => {
      var jsonData = response.data;

      if (jsonData.result) {
        if (jsonData.data != null && jsonData.data != "") {
          this.companyResult = jsonData.data;
        }
      }
    });

    this.loadTree();

    setTimeout(() => {
      this.tableHeight = pageUtil.autoAdjustHeight(this.$refs.formTable.$el);
    }, 1000);
  },
  methods: {
    getSelectedValue(value) {
      this.queryModel.parentId = value;
    },
    loadTree() {
      var formData = new FormData();

      companyInfoApi.loadChildren(formData).then((resp) => {
        var jsonData = resp.data;

        if (jsonData.result) {
          this.treeData = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    loadChildren(tree, treeNode, resolve) {
      var formData = new FormData();
      formData.append("parentId", tree.id);

      companyInfoApi.loadChildren(formData).then((resp) => {
        var jsonData = resp.data;

        if (jsonData.result) {
          resolve(jsonData.data);
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    indexMethod(index) {
      return (this.pageIndex - 1) * this.pageSize + (index + 1);
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("personName", self.queryModel.personName);
      formData.append("parentId", self.queryModel.parentId);
      formData.append("subordinate", self.queryModel.subordinate);

      formData.append("type", self.queryModel.type);
      formData.append("status", self.queryModel.status);

      if (self.queryModel.fillDayRange[0] != null) {
        formData.append("startTime", self.queryModel.fillDayRange[0]);
      }

      if (self.queryModel.fillDayRange[1] != null) {
        formData.append("endTime", self.queryModel.fillDayRange[1]);
      }

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      fillAttendanceApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleOpenFiles(record) {
      this.modalTitle = "查看附件";
      this.businessKey = record.id;
      this.showModal = true;
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
  },
  components: {
    "fillAttendanceFiles-list": FillAttendanceFilesList,
    "el-select-tree": SelectTree,
  },
  mounted: function () {
    this.changePage(1);
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>