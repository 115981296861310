<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-table :data="tableData" style="min-height:400px;" v-loading="loading" stripe>
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column prop="fileName" label="附件名称" width="180"></el-table-column>
        <el-table-column prop="fileUrl" label="附件地址" width="400" show-overflow-tooltip>
          <template slot-scope="{row}">
            <el-link :href="row.fileUrl" target="_blank">{{row.fileUrl}}</el-link>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import fillAttendanceFilesApi from "@/api/business/fillAttendanceFiles";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  props: ["businessKey", "title"],
  data() {
    var self = this;
    return {
      loading: false,
      tableData: [],
      field: "",
      direction: "",
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      showDialog: true
    };
  },
  methods: {
    queryList() {
      var self = this;

      self.loading = true;
      var formData = new FormData();

      if (this.field != null) {
        formData.append("fillAttendanceId", this.businessKey);
      }

      fillAttendanceFilesApi
        .list(formData)
        .then(function(response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch(error => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    closeDialog() {
      this.$emit("close", false);
    }
  },
  mounted: function() {
    this.queryList();
  }
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>